/* eslint-disable space-in-parens */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import image1 from "./DrImages/image1.png";
import image2 from "./DrImages/image2.png";
import image3 from "./DrImages/image3.png";
import Drcard from "./drcard/drcard";
import CustomButton from "../components/CustomButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Skeleton from "react-loading-skeleton";
import NoAppointmentCard from "../Dashboard/PatientAppointment/NoAppointmentCard/NoAppointmentCard";
import HCFDrCard from "../pages/PatientModule/PatientHCF/DrDetailsCard/Labs/HCFDrCard";


// export const baseURL = "http://localhost:3000";
export const baseURL = "https://api.shareecare.com";
export const front_end_url = "http://localhost:8000";
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,55}$/;
export const numberRegex = /^\d+$/;

export const data = [
    {
        id: 1,
        drimage: image1,
        name: "Dr. Elizabeth Davis",
        hospital: "Xyz Hospital",
        specialist: "Neurologist",
        rating: "4.5",
        reviews: "(200) Review",
    },
    {
        id: 2,
        drimage: image2,
        name: "Dr. William",
        hospital: "Gangaram Hospital",
        specialist: "Pediatric",
        rating: "4.3",
        reviews: "(400) Review",
    },
    {
        id: 3,
        drimage: image3,
        name: "Dr. Arbaaz Khan",
        hospital: "RML Hospital",
        specialist: "Nutritionist",
        rating: "3.9",
        reviews: "(900) Review",
    },
];

export const CallCardData = ({ sendCardData, textField, linkPath, loading, hcfID=null }) => {
    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 200;
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 200;
        }
    };

    console.log("this is the hcf id : ",hcfID?.hcfID);

    return (
        <Box sx={{ width: "100%", marginTop: "1%" }}>
                        {textField && textField.trim() && ( // Only render if textField is not null or empty

            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative", paddingBottom: "10px" }}>
                <h4 sx={{ fontWeight: "bold", position: "absolute", top: 0, left: 0 }}>
                    {textField}
                </h4>
            </Box>
                        )}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                }}
            >
                <IconButton aria-label="Scroll left" onClick={scrollLeft}>
                    <ChevronLeftIcon />
                </IconButton>
                <div
                    ref={containerRef}
                    style={{
                        width: "calc(100% - 48px)",
                        overflowX: "auto",
                        display: "flex",
                        scrollbarWidth: "none",
                        scrollBehavior: "smooth",
                    }}
                >
                    <Box sx={{ borderRadius: 1, display: "flex" }}>
                        {
                        loading ? (
                            [...Array(10)].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    height="8rem"
                                    width="20em"
                                    style={{ marginRight: "10px", borderRadius: "8px" }}
                                />
                            ))
                        ) : sendCardData?.length === 0 ? (
                            <NoAppointmentCard
                                style={{ height: "8rem" }}
                                text_one="No Data found"
                            />
                        ) : (
                            sendCardData?.slice(0, 10).map((dataprop, index) => (
                                <Link
                                    to={ hcfID === null || undefined ?  `${linkPath}${dataprop.suid}` : `${linkPath}${dataprop.suid}/${hcfID?.hcfID}`}
                                    style={{
                                        width: "20em",
                                        textDecoration: "none",
                                        marginRight: "10px",
                                    }}
                                    key={index}
                                >
                                    <Drcard DrData={dataprop}/>
                                </Link>
                            ))
                        )}
                    </Box>
                </div>
                <IconButton aria-label="Scroll right" onClick={scrollRight}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
        </Box>
    );
};

export const currencysign = "₹";
export const patient_suid = localStorage.getItem("patient_suid");

export const HCFCardsData = ({ sendCardData, textField, linkPath, loading }) => {
    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 200;
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 200;
        }
    };

    return (
        <Box sx={{ width: "100%", marginTop: "1%" }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                }}
            >
                <IconButton aria-label="Scroll left" onClick={scrollLeft}>
                    <ChevronLeftIcon />
                </IconButton>
                <div
                    ref={containerRef}
                    style={{
                        width: "calc(100% - 48px)",
                        overflowX: "auto",
                        display: "flex",
                        scrollbarWidth: "none",
                        scrollBehavior: "smooth",
                    }}
                >
                    <Box sx={{ borderRadius: 1, display: "flex", width: "100%" }}>
                        {
                        loading ? (
                            [...Array(10)].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    height="8rem"
                                    width="20em"
                                    style={{ marginRight: "10px", borderRadius: "8px" }}
                                />
                            ))
                        ) : sendCardData?.length === 0 ? (
                            <NoAppointmentCard
                                style={{ height: "8rem" }}
                                text_one="No Data found"
                            />
                        ) : (
                            sendCardData?.slice(0, 10).map((dataprop, index) => (
                                <HCFDrCard data={dataprop} key={index} />
                            ))
                        )}
                    </Box>
                </div>
                <IconButton aria-label="Scroll right" onClick={scrollRight}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
        </Box>
    );
};


export const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
};

export const formatOnlyDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};

export const formatDateDay = (isoDate) => {
    if (!isoDate) {
        return ""; // Return an empty string or any default message you prefer
    }

    try {
        const date = new Date(isoDate);
        if (isNaN(date)) {
            return ""; // Return empty string if the date is invalid
        }

        return date.toLocaleString("en-US", {
            weekday: "short", // Short form of the day, e.g., "Fri"
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    } catch (error) {
        return ""; // Fallback for unexpected errors
    }
};


export const formatTime = (timeString) => {
    if (!timeString || typeof timeString !== 'string') {
        return 'Invalid Time'; // Fallback for undefined or invalid input
    }

    const [hours, minutes] = timeString.split(':');
    const hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert 0 or 12 to 12 for 12-hour format
    return `${formattedHour}:${minutes}${isPM ? 'PM' : 'AM'}`;
};

